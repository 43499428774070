<!--- MENU ---->
<ion-menu contentId="principal" id="menu_principal" menuId="menu_principal" color="light">
  <ion-header>
    <ion-toolbar>
      <ion-icon slot="start" class="icon-menu" src="/assets/icon/user.svg"></ion-icon>
      <ion-buttons slot="start">
        <ion-button (click)="pushPainelUsuarioLogado()" class="btn-perfil-usuario">
          <ion-label class="label-name-usuario" text-wrap>{{conta_logada.nome}}</ion-label>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item (click)="pushPage('home')" *ngIf="acessoPainel !== undefined">
        <ion-icon name="apps-outline" slot="start"></ion-icon>
        <ion-label>Painel</ion-label>
      </ion-item>

      <!-- Listagem -->
      <div *ngFor="let opt of pathListagem">
        <ion-item (click)="pushPage(opt.path)" *ngIf="opt.possui_acesso">
          <ion-icon name="list-outline" slot="start"></ion-icon>
          <ion-label>{{opt.nome_path}}</ion-label>
        </ion-item>
      </div>

      <!-- Cadastro -->
      <ion-item (click)="setShowMenuCadastro()" *ngIf="pathCadastro.length > 0">
        <ion-icon src="/assets/icon/cadastro.svg" slot="start"></ion-icon>
        <ion-label>Cadastro</ion-label>
      </ion-item>
      <ion-item-group submenu [class.visible]="showMenuCadastro">
        <div *ngFor="let opt of pathCadastro">
          <ion-item submenu-item (click)="pushPage(opt.path)" *ngIf="opt.possui_acesso">
            <ion-icon src="/assets/icon/open.svg" slot="start"></ion-icon>
            <ion-label>{{opt.nome_path}}</ion-label>
          </ion-item>
        </div>
      </ion-item-group>

      <!-- Auditoria -->
      <ion-item (click)="setShowMenuAuditoria()" *ngIf="pathAuditoria.length > 0">
        <ion-icon src="/assets/icon/auditoria.svg" slot="start"></ion-icon>
        <ion-label>Auditoria</ion-label>
      </ion-item>
      <ion-item-group submenu [class.visible]="showMenuAuditoria" *ngIf="pathAuditoria.length > 0">
        <div *ngFor="let opt of pathAuditoria">
          <ion-item submenu-item (click)="pushPage(opt.path)" *ngIf="opt.possui_acesso">
            <ion-icon src="/assets/icon/open.svg" slot="start"></ion-icon>
            <ion-label>{{opt.nome_path}}</ion-label>
          </ion-item>
        </div>
      </ion-item-group>

      <!-- Fiscalização -->
      <ion-item (click)="setShowMenuFiscalizacao()" *ngIf="pathFiscalizacao.length > 0">
        <ion-icon src="/assets/icon/auditoria.svg" slot="start"></ion-icon>
        <ion-label>Fiscalização</ion-label>
      </ion-item>
      <ion-item-group submenu [class.visible]="showMenuFiscalizacao" *ngIf="pathFiscalizacao.length > 0">
        <div *ngFor="let opt of pathFiscalizacao">
          <ion-item submenu-item (click)="pushPage(opt.path)" *ngIf="opt.possui_acesso">
            <ion-icon src="/assets/icon/open.svg" slot="start"></ion-icon>
            <ion-label>{{opt.nome_path}}</ion-label>
          </ion-item>
        </div>
      </ion-item-group>



      <!-- Relatorio -->
      <ion-item (click)="setShowMenuRelatorio()" *ngIf="pathRelatorio.length > 0">        
        <ion-icon name="document-text-outline" slot="start"></ion-icon>
        <ion-label>Relatórios</ion-label>
      </ion-item>
      <ion-item-group submenu [class.visible]="showMenuRelatorio" *ngIf="pathRelatorio.length > 0">
        <div *ngFor="let opt of pathRelatorio">
          <ion-item submenu-item (click)="pushPage(opt.path)" *ngIf="opt.possui_acesso">            
            <ion-icon name="document-text-outline" slot="start"></ion-icon>
            <ion-label>{{opt.nome_path}}</ion-label>
          </ion-item>
        </div>
      </ion-item-group>

      <!-- Email -->
      <ion-item (click)="setShowMenuEmail()" *ngIf="pathEmail.length > 0">        
        <ion-icon name="mail-outline" slot="start"></ion-icon>
        <ion-label>E-mails</ion-label>
      </ion-item>
      <ion-item-group submenu [class.visible]="showMenuEmail" *ngIf="pathEmail.length > 0">
        <div *ngFor="let opt of pathEmail">
          <ion-item submenu-item (click)="pushPage(opt.path)" *ngIf="opt.possui_acesso">                        
            <ion-icon name="mail-outline" slot="start"></ion-icon>
            <ion-label>{{opt.nome_path}}</ion-label>
          </ion-item>
        </div>
      </ion-item-group>      



      <!-- Configuração -->
      <ion-item (click)="setShowMenuConfiguracao()" *ngIf="pathConfiguracao.length > 0">        
        <ion-icon name="cog-outline" slot="start"></ion-icon>
        <ion-label>Configuração</ion-label>
      </ion-item>
      <ion-item-group submenu [class.visible]="showMenuConfiguracao" *ngIf="pathConfiguracao.length > 0">
        <div *ngFor="let opt of pathConfiguracao">
          <ion-item submenu-item (click)="pushPage(opt.path)" *ngIf="opt.possui_acesso">                        
            <ion-icon name="cog-outline" slot="start"></ion-icon>
            <ion-label>{{opt.nome_path}}</ion-label>
          </ion-item>
        </div>
      </ion-item-group>      


      <ion-item (click)="logout()">
        <ion-icon name="exit" slot="start"></ion-icon>
        <ion-label>Sair</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-img class="logo-menu" src="assets/image/protix-logo.png"></ion-img>
</ion-menu>
<ion-router-outlet id="principal"></ion-router-outlet>