import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Login, LoginService } from "../services/login.service";

// Alterar posteriormente
// const APIURL = "https://tato-tst.crescer.net";
//const APIURL = "http://10.10.1.124:8043";
const APIURL = "https://agetst-api.protix.com.br";

export function getURL() {
  return APIURL;
}
@Injectable({
  providedIn: "root",
})
export class ApiService {
  public loginSessao: Login = {} as Login;

  constructor(private http: HttpClient, private loginService: LoginService)  {
    this.initializeLogin();
    
  }

  

  httpOptionsNoCredentials = {
    headers: new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT",
      Accept: "application/json",
    }),
    params: {},
    responseType: 'json' as const,
  };

  httpOptionsCredentials = {
    headers: new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT",
      enctype: "multipart/form-data; boundary=WebAppBoundary",      
      Accept: "application/json",
    }).append("Access-Control-Allow-Credentials", "true"), // Modifiquei aqui para usar append
    params: {},
  };

  private async initializeLogin() {
    const storageInitialized = await this.loginService.isStorageInitialized();
    if (storageInitialized) {
      this.loginService.getLogin().then((login: Login) => {
        this.loginSessao = login;
      }).catch(error => {
        console.error("Erro ao carregar login:", error);
      });
    } else {
      console.error('Storage n�o inicializado corretamente');
    }
  }

  login(params:any) {
    this.httpOptionsCredentials.params = params;    
    return this.http.request("POST", APIURL + "/login", this.httpOptionsCredentials);
  }

  resetSenha(params:any) {
    this.httpOptionsCredentials.params = params;    
    return this.http.request("POST", APIURL + "/reset_senha", this.httpOptionsCredentials);
  }


  
  sendData(params:any, route:any): Observable<any> {
    const form_data = new FormData();
    let params_new = params;
    params_new = this.setParamsSessao(params_new, null);    
    form_data.append("params", JSON.stringify(params_new));
    
    /*for (const pair of form_data.entries()) {
      console.log(pair[0], pair[1]);
    }*/

    return this.http.post(APIURL + "/" + route, form_data, this.httpOptionsCredentials);
  }

  sendData2(params:any, route:any): Observable<any> {
    const form_data = new FormData();
    let params_new = params;
    params_new = this.setParamsSessao(params_new, null);
    form_data.append("params", JSON.stringify(params_new));

    /*for (const pair of form_data.entries()) {
      console.log(pair[0], pair[1]);
    }*/
    return this.http.post(APIURL + "/" + route, form_data, this.httpOptionsNoCredentials);
  }

  getFile(params: any, route: any): Observable<any> {
    const form_data = new FormData();
    let params_new = params;
    params_new = this.setParamsSessao(params_new, null);
    form_data.append("params", JSON.stringify(params_new));
    
    // Alterando o responseType para 'blob' para o download de arquivos
    const httpOptionsWithBlob = {
      ...this.httpOptionsNoCredentials, // Mant�m as outras op��es
      responseType: 'blob' as const,     // Altera apenas o responseType
    };
  
    return this.http.post(APIURL + "/" + route, form_data, httpOptionsWithBlob);
  }

  sendDataAndFile(params:any, paramsFile: File[], route:any): Observable<any> {
    const form_data = new FormData();
    let params_new = params;
    params_new = this.setParamsSessao(params_new, null);
    if (paramsFile != undefined) {
      for (const file of paramsFile) {
        form_data.append('file_anexo[]', file, file.name);
      }
    }
    form_data.append("params", JSON.stringify(params_new));    
    //this.httpOptions["responseType"] = "";
    return this.http.post(APIURL + "/" + route, form_data, this.httpOptionsCredentials);
  }

  syncData(route:any, login = {}): Observable<any> {
    let params_new = this.setParamsSessao({}, login);
    this.httpOptionsCredentials.params = params_new;        
    return this.http.request("POST", APIURL + "/" + route, this.httpOptionsCredentials);
  }

  private setParamsSessao(params:any, login:any) {
    if (this.loginSessao != null) {
      params["token"] = this.loginSessao.token;
      params["token_exp"] = this.loginSessao.token_exp;
    }else{
      if (login != null) {
        this.loginSessao = login;
        params["token"] = this.loginSessao.token;
        params["token_exp"] = this.loginSessao.token_exp;
      }
    }
    
    
    return params;
  }
}
