import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

export interface Login {
  token: string;
  token_exp: string;
  conta: Conta;
  sincronismo_inicial_realizado: boolean;
}

export interface Conta {
  usuario_id: number;
  nome?: string;
  login: string;
  email: string;
  perfil_id: number;
  telefone: string;
  agencia_preferencia_id?: number;
  municipio_preferencia_id?: number;
  prestador_preferencia_id?: number;
  ano_preferencia_id?: number;
  primeiro_login: boolean;
  termo_uso_aceito: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private storage: Storage,
    private toastController: ToastController,
  ) {

  }

  async isStorageInitialized(): Promise<boolean> {
    try {
      // Tentando acessar o Storage
      await this.storage.create();
      return true; // Se não houve erro, o Storage foi criado corretamente
    } catch (error) {
      console.error("Erro ao inicializar o Storage:", error);
      return false; // Se ocorreu erro, o Storage não foi inicializado corretamente
    }
  }

  async addLogin(login: Login): Promise<any> {
    return await this.storage.set("login", login);
  }

  async getLogin(): Promise<Login> {
    return this.storage.get("login");
  }

  destroyLogin() {
    return this.storage.remove("login");
  }

  setSincronismoInicial(realizado: boolean) {
    var login_atual = {} as Login;
    this.getLogin().then(
      (login: Login) => {
        login_atual = login;
        login_atual.sincronismo_inicial_realizado = realizado;
        this.addLogin(login_atual);
      }
    )
  }

  async setPrimeiroLogin(primeiroLogin: boolean): Promise<void> {
    var login_atual = {} as Login;
    await this.getLogin().then(
      async (login: Login) => {
        login_atual = login;
        login_atual.conta.primeiro_login = primeiroLogin;
        await this.addLogin(login_atual);
      }
    )
  }

  async setTermoUso(termoUsoAceito: boolean): Promise<void> {
    var login_atual = {} as Login;
    await this.getLogin().then(
      async (login: Login) => {
        login_atual = login;
        login_atual.conta.termo_uso_aceito = termoUsoAceito;
        await this.addLogin(login_atual);
      }
    )
  }

  async salvaLogin(respostaServer: Login): Promise<void> {
    var newLogin: Login = <Login>{};
    newLogin = respostaServer;
    newLogin.sincronismo_inicial_realizado = false;
    return await this.addLogin(newLogin);
  }
}
