import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { Conta } from 'src/app/services/login.service';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.page.html',
  styleUrls: ['./logout-modal.page.scss'],
  standalone: false
})
export class LogoutModalPage implements OnInit {
  contaLogada: Conta = {} as Conta;
  tempo_restante: number = 0;
  constructor(
    private modalController: ModalController,
    private params: NavParams,
  ) { }

  ngOnInit() {
    this.contaLogada = this.params.data['contaLogada'];
    this.tempo_restante = 60;
    setInterval(
      () => {
        this.tempo_restante--;
        if (this.tempo_restante == 0) {
          this.modalController.dismiss({ efetua_logout: true });
        }
      }, 1000
    );
  }

  setStopLogout() {
    this.modalController.dismiss({ efetua_logout: false });
  }

}
