import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PermissaoAcessoGuard } from './core/guards/permissao-acesso.guard';
import { MenuComponent } from "./core/componente/menu/menu.component";


const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import("./pages/auth/login/login.module").then((m) => m.LoginPageModule)
  },
  {
    path: "field-status",
    loadChildren: () => import("./core/componente/field-status/field-status.module").then((m) => m.FieldStatusModule)
  },
  {
    path: "logout",
    loadChildren: () => import("./core/componente/menu/logout-modal/logout-modal.module").then((m) => m.LogoutModalPageModule)
  },
  {
    path: "home",
    loadChildren: () => import("./pages/home/home.module").then((m) => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "usuario",
    loadChildren: () =>
      import("./pages/cadastro/usuario/usuario.module").then(
        (m) => m.UsuarioPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard],
  },
  {
    path: "usuario-form",
    loadChildren: () =>
      import("./pages/cadastro/usuario/usuario-form/usuario-form.module").then(
        (m) => m.UsuarioFormPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "perfil",
    loadChildren: () =>
      import("./pages/cadastro/perfil/perfil.module").then(
        (m) => m.PerfilPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "perfil-form",
    loadChildren: () =>
      import("./pages/cadastro/perfil//perfil-form/perfil-form.module").then(
        (m) => m.PerfilFormPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "usuario-painel",
    loadChildren: () =>
      import(
        "./pages/cadastro/usuario/usuario-painel/usuario-painel.module"
      ).then((m) => m.UsuarioPainelPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "altera-senha",
    loadChildren: () =>
      import(
        "./pages/cadastro/usuario/usuario-painel/altera-senha/altera-senha.module"
      ).then((m) => m.AlteraSenhaPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "agencia-reguladora",
    loadChildren: () =>
      import(
        "./pages/cadastro/agencia-reguladora/agencia-reguladora.module"
      ).then((m) => m.AgenciaReguladoraPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "agencia-form",
    loadChildren: () =>
      import(
        "./pages/cadastro/agencia-reguladora/agencia-form/agencia-form.module"
      ).then((m) => m.AgenciaFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "municipio",
    loadChildren: () =>
      import("./pages/cadastro/municipio/municipio.module").then(
        (m) => m.MunicipioPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "municipio-form",
    loadChildren: () =>
      import(
        "./pages/cadastro/municipio/municipio-form/municipio-form.module"
      ).then((m) => m.MunicipioFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "municipio-painel",
    loadChildren: () =>
      import(
        "./pages/cadastro/municipio/municipio-painel/municipio-painel.module"
      ).then((m) => m.MunicipioPainelPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "prestador",
    loadChildren: () =>
      import("./pages/cadastro/prestador/prestador.module").then(
        (m) => m.PrestadorPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "prestador-form",
    loadChildren: () =>
      import(
        "./pages/cadastro/prestador/prestador-form/prestador-form.module"
      ).then((m) => m.PrestadorFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "agencia-municipio",
    loadChildren: () =>
      import(
        "./pages/cadastro/agencia-municipio/agencia-municipio.module"
      ).then((m) => m.AgenciaMunicipioPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: "agencia-municipio-form",
    loadChildren: () =>
      import(
        "./pages/cadastro/agencia-municipio/agencia-municipio-form/agencia-municipio-form.module"
      ).then((m) => m.AgenciaMunicipioFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "municipio-prestador",
    loadChildren: () =>
      import(
        "./pages/cadastro/municipio-prestador/municipio-prestador.module"
      ).then((m) => m.MunicipioPrestadorPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "municipio-prestador-form",
    loadChildren: () =>
      import(
        "./pages/cadastro/municipio-prestador/municipio-prestador-form/municipio-prestador-form.module"
      ).then((m) => m.MunicipioPrestadorFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "indicador",
    loadChildren: () =>
      import("./pages/cadastro/indicador/indicador.module").then(
        (m) => m.IndicadorPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "indicador-form",
    loadChildren: () =>
      import(
        "./pages/cadastro/indicador/indicador-form/indicador-form.module"
      ).then((m) => m.IndicadorFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "dado",
    loadChildren: () =>
      import("./pages/cadastro/dado/dado.module").then((m) => m.DadoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "dado-form",
    loadChildren: () =>
      import("./pages/cadastro/dado/dado-form/dado-form.module").then(
        (m) => m.DadoFormPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "usuario-atribuicao",
    loadChildren: () =>
      import(
        "./pages/cadastro/usuario/usuario-atribuicao/usuario-atribuicao.module"
      ).then((m) => m.UsuarioAtribuicaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "usuario-atribuicao-lote",
    loadChildren: () =>
      import(
        "./pages/cadastro/usuario/usuario-atribuicao-lote/usuario-atribuicao-lote.module"
      ).then((m) => m.UsuarioAtribuicaoLotePageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "reset-senha",
    loadChildren: () =>
      import("./pages/auth/reset-senha/reset-senha.module").then(
        (m) => m.ResetSenhaPageModule
      )
  },
  {
    path: "ano",
    loadChildren: () =>
      import("./pages/cadastro/ano/ano.module").then((m) => m.AnoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "ano-form",
    loadChildren: () =>
      import("./pages/cadastro/ano/ano-form/ano-form.module").then(
        (m) => m.AnoFormPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "equacao",
    loadChildren: () =>
      import("./pages/cadastro/equacao/equacao.module").then(
        (m) => m.EquacaoPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "equacao-form",
    loadChildren: () =>
      import("./pages/cadastro/equacao/equacao-form/equacao-form.module").then(
        (m) => m.EquacaoFormPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "equacao-copy-form",
    loadChildren: () =>
      import(
        "./pages/cadastro/equacao/equacao-copy-form/equacao-copy-form.module"
      ).then((m) => m.EquacaoCopyFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "dados",
    loadChildren: () =>
      import("./pages/listagem/dados/dados.module").then(
        (m) => m.DadosPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "dados-dissertativo",
    loadChildren: () =>
      import("./pages/listagem/dados-dissertativo/dados-dissertativo.module").then(
        (m) => m.DadosDissertativoPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },  
  {
    path: "indicadores",
    loadChildren: () =>
      import("./pages/listagem/indicadores/indicadores.module").then(
        (m) => m.IndicadoresPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "auditoria-teste-controle",
    loadChildren: () =>
      import(
        "./pages/cadastro/auditoria-teste-controle/auditoria-teste-controle.module"
      ).then((m) => m.AuditoriaTesteControlePageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "auditoria-teste-controle-form",
    loadChildren: () =>
      import(
        "./pages/cadastro/auditoria-teste-controle/auditoria-teste-controle-form/auditoria-teste-controle-form.module"
      ).then((m) => m.AuditoriaTesteControleFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'auditoria-status-constatacao-atributo',
    loadChildren: () =>
      import(
        './pages/cadastro/auditoria-status-constatacao-atributo/auditoria-status-constatacao-atributo.module'
      ).then(m => m.AuditoriaStatusConstatacaoAtributoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'auditoria-status-constatacao-atributo-form',
    loadChildren: () => import(
      './pages/cadastro/auditoria-status-constatacao-atributo//auditoria-status-constatacao-atributo-form/auditoria-status-constatacao-atributo-form.module'
    ).then(m => m.AuditoriaStatusConstatacaoAtributoFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'preferencia-usuario',
    loadChildren: () => import('./pages/cadastro/usuario/usuario-painel//preferencia-usuario/preferencia-usuario.module').then(m => m.PreferenciaUsuarioPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'auditoria-teste-controle-constatacao',
    loadChildren: () => import('./pages/cadastro/auditoria-teste-controle-constatacao/auditoria-teste-controle-constatacao.module').then(m => m.AuditoriaTesteControleConstatacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'calculo-teste-controle-dado',
    loadChildren: () => import('./pages/listagem/auditoria/calculo-teste-controle-dado/calculo-teste-controle-dado.module').then(m => m.CalculoTesteControleDadoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/listagem/auditoria/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'auditoria-teste-substantivo',
    loadChildren: () => import('./pages/cadastro/auditoria/auditoria-teste-substantivo/auditoria-teste-substantivo.module').then(m => m.AuditoriaTesteSubstantivoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'auditoria-teste-substantivo-form',
    loadChildren: () => import('./pages/cadastro/auditoria/auditoria-teste-substantivo/auditoria-teste-substantivo-form/auditoria-teste-substantivo-form.module').then(m => m.AuditoriaTesteSubstantivoFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'painel-auditoria',
    loadChildren: () => import('./pages/painel/painel-auditoria/painel-auditoria.module').then(m => m.PainelAuditoriaPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'teste-controle-constatacao-solicitacao-informacao',
    loadChildren: () => import('./pages/cadastro/auditoria/teste-controle-constatacao-solicitacao-informacao/teste-controle-constatacao-solicitacao-informacao.module').then(m => m.TesteControleConstatacaoSolicitacaoInformacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'acao',
    loadChildren: () => import('./pages/cadastro/acao/acao.module').then(m => m.AcaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'relatorio-constatacao-teste-controle',
    loadChildren: () => import('./pages/relatorios/constatacao-teste-controle/constatacao-teste-controle.module').then(m => m.ConstatacaoTesteControlePageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'relatorio-constatacao-teste-fiscalizacao',
    loadChildren: () => import('./pages/relatorios/constatacao-teste-fiscalizacao/constatacao-teste-fiscalizacao.module').then(m => m.ConstatacaoTesteFiscalizacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'relatorio-constatacao-teste-substantivo',
    loadChildren: () => import('./pages/relatorios/constatacao-teste-substantivo/constatacao-teste-substantivo.module').then(m => m.ConstatacaoTesteSubstantivoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },  
  {
    path: 'notificacao',
    loadChildren: () => import('./pages/listagem/informacao/notificacao/notificacao.module').then(m => m.NotificacaoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'log-altesracoes-solicitacoes-informacoes',
    loadChildren: () => import('./pages/relatorios/log-altesracoes-solicitacoes-informacoes/log-altesracoes-solicitacoes-informacoes.module').then(m => m.LogAltesracoesSolicitacoesInformacoesPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'termo-uso',
    loadChildren: () => import('./pages/cadastro/termo-uso/termo-uso.module').then(m => m.TermoUsoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'cadastro-notificacao',
    loadChildren: () => import('./pages/cadastro/informacao/notificacao/notificacao.module').then(m => m.NotificacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'rol-geral-por-municipio-processos',
    loadChildren: () => import('./pages/relatorios/rol-geral-por-municipio-processos/rol-geral-por-municipio-processos.module').then(m => m.RolGeralPorMunicipioProcessosPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'teste-substantivo-constatacao-evidencia-substantiva',
    loadChildren: () => import('./pages/cadastro/auditoria/teste-substantivo-constatacao-evidencia-substantiva/teste-substantivo-constatacao-evidencia-substantiva.module').then(m => m.TesteSubstantivoConstatacaoEvidenciaSubstantivaPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'teste-substantivo-constatacao',
    loadChildren: () => import('./pages/cadastro/auditoria/teste-substantivo-constatacao/teste-substantivo-constatacao.module').then(m => m.TesteSubstantivoConstatacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'certificacao-prestador',
    loadChildren: () => import('./pages/relatorios/certificacao-prestador/certificacao-prestador.module').then(m => m.CertificacaoPrestadorPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'exportacao',
    loadChildren: () => import('./pages/relatorios/exportacao/exportacao.module').then( m => m.ExportacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'dados-usuario',
    loadChildren: () => import('./pages/relatorios/dados-usuario/dados-usuario.module').then( m => m.DadosUsuarioPageModule)
  },
  {
    path: 'gerenciar-imagens',
    loadChildren: () => import('./pages/configuracao/gerenciar-imagens/gerenciar-imagens.module').then( m => m.GerenciarImagensPageModule)
  },
  {
    path: 'imagens',
    loadChildren: () => import('./pages/configuracao/imagens/imagens.module').then( m => m.ImagensPageModule)
  },
  {
    path: 'imagens-form',
    loadChildren: () => import(
      './pages/configuracao/imagens/imagens-form/imagens-form.module'
    ).then(m => m.ImagensFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'dash-teste-controle',
    loadChildren: () => import('./pages/relatorios/dash-teste-controle/dash-teste-controle.module').then( m => m.DashTesteControlePageModule)
  },
  {
    path: 'dash-teste-substantivo',
    loadChildren: () => import('./pages/relatorios/dash-teste-substantivo/dash-teste-substantivo.module').then( m => m.DashTesteSubstantivoPageModule)
  },
  {
    path: 'dash-ct-detalhado',
    loadChildren: () => import('./pages/relatorios/dash-ct-detalhado/dash-ct-detalhado.module').then( m => m.DashCtDetalhadoPageModule)
  },
  {
    path: 'ativo',
    loadChildren: () => import('./pages/cadastro/ativo/ativo.module').then( m => m.AtivoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'ativo-form',
    loadChildren: () => import('./pages/cadastro/ativo/ativo-form/ativo-form.module').then( m => m.AtivoFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'tipo-ativo',
    loadChildren: () => import('./pages/cadastro/tipo-ativo/tipo-ativo.module').then( m => m.TipoAtivoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'tipo-ativo-form',
    loadChildren: () => import('./pages/cadastro/tipo-ativo/tipo-ativo-form/tipo-ativo-form.module').then( m => m.TipoAtivoFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },  
  {
    path: 'teste-fiscalizacao',
    loadChildren: () => import('./pages/cadastro/teste-fiscalizacao/teste-fiscalizacao.module').then( m => m.TesteFiscalizacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'teste-fiscalizacao-form',
    loadChildren: () => import('./pages/cadastro/teste-fiscalizacao/teste-fiscalizacao-form/teste-fiscalizacao-form.module').then( m => m.TesteFiscalizacaoFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'painel-fiscalizacao',
    loadChildren: () => import('./pages/painel/painel-fiscalizacao/painel-fiscalizacao.module').then( m => m.PainelFiscalizacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'teste-fiscalizacao-constatacao',
    loadChildren: () => import('./pages/cadastro/teste-fiscalizacao-constatacao/teste-fiscalizacao-constatacao.module').then( m => m.TesteFiscalizacaoConstatacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'fiscalizacao-status-constatacao-atributo',
    loadChildren: () => import('./pages/cadastro/fiscalizacao-status-constatacao-atributo/fiscalizacao-status-constatacao-atributo.module').then( m => m.FiscalizacaoStatusConstatacaoAtributoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'fiscalizacao-status-constatacao-atributo-form',
    loadChildren: () => import('./pages/cadastro/fiscalizacao-status-constatacao-atributo/fiscalizacao-status-constatacao-atributo-form/fiscalizacao-status-constatacao-atributo-form.module').then( m => m.FiscalizacaoStatusConstatacaoAtributoFormPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'teste-fiscalizacao-constatacao-solicitacao-informacao',
    loadChildren: () => import('./pages/cadastro/fiscalizacao/teste-fiscalizacao-constatacao-solicitacao-informacao/teste-fiscalizacao-constatacao-solicitacao-informacao.module').then( m => m.TesteFiscalizacaoConstatacaoSolicitacaoInformacaoPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },

  {
    path: 'geral',
    loadChildren: () => import('./pages/configuracao/geral/geral.module').then( m => m.GeralPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'equacao-certificacao-indicador',
    loadChildren: () => import('./pages/cadastro/equacao-certificacao-indicador/equacao-certificacao-indicador.module').then( m => m.EquacaoCertificacaoIndicadorPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: "equacao-certificacao-indicador-form",
    loadChildren: () =>
      import("./pages/cadastro/equacao-certificacao-indicador/equacao-certificacao-indicador-form/equacao-certificacao-indicador-form.module").then(
        (m) => m.EquacaoCertificacaoIndicadorFormPageModule
      ),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },

  {
    path: "equacao-certificacao-indicador-copy-form",
    loadChildren: () =>
      import(
        "./pages/cadastro/equacao-certificacao-indicador/equacao-certificacao-indicador-copy-form/equacao-certificacao-indicador-copy-form.module"
      ).then((m) => m.EquacaoCertificacaoIndicadorCopyFormPageModule),
      
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },  
  
  {
    path: 'certificacao-indicador',
    loadChildren: () => import('./pages/relatorios/certificacao-indicador/certificacao-indicador.module').then( m => m.CertificacaoIndicadorPageModule),
    canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/email/welcome/welcome.module').then( m => m.WelcomePageModule),
	canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/email/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule),
	canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'send-manual',
    loadChildren: () => import('./pages/email/send-manual/send-manual.module').then( m => m.SendManualPageModule),
	canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'send-notification',
    loadChildren: () => import('./pages/email/send-notification/send-notification.module').then( m => m.SendNotificationPageModule),
	canActivate: [AuthGuard, PermissaoAcessoGuard]
  },
  {
    path: 'untyped-data',
    loadChildren: () => import('./pages/email/untyped-data/untyped-data.module').then( m => m.UntypedDataPageModule),
	canActivate: [AuthGuard, PermissaoAcessoGuard ]
  },

 

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
