<ion-content>
  <div id="div_logout">
    <div class="cabecalho-logout">Atenção {{contaLogada.nome}}</div>
    <div class="mensagem-logout">Por estar inativo à 10 minutos será efetuado o logoff em :</div>
    <div class="tempo-logout">{{tempo_restante}} segundos</div>
  </div>
  <ion-button expand="block" (click)="setStopLogout()" class="btn-stop-logout" color="primary">
    <ion-icon slot="start" name="hand-left-outline">
    </ion-icon>
    Estou presente
  </ion-button>
</ion-content>