import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OverlayService } from '../services/overlay.service';
import { LoginService, Login } from '../../services/login.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthState(state.url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

  constructor(
    private loginService: LoginService,
    private router: Router,
    private overlayService: OverlayService
  ) { }

  private async checkAuthState(redirect: String): Promise<boolean> {
    if (await !this.loginService.isStorageInitialized()){
      return false;
    }
    return this.loginService.getLogin().then(
      (login: Login) => {
        if (login == null) {
          this.router.navigate(['/login'], {
            queryParams: { redirect }
          });
          return false;
        } else {
          // Token expirado
          if (Date.parse(login.token_exp) < Date.now()) {
            this.loginService.destroyLogin();
            this.router.navigate(['/login'], {
              queryParams: { redirect }
            });
            this.overlayService.toast({
              message: "Token expirado, favor realizar um novo login",
              color: 'danger'
            });
            return false
          } else {
            return true
          }
        }
      }
    )
    
  }

}
