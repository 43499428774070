import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Login, LoginService } from 'src/app/services/login.service';
import { PerfilAcesso, PerfilService } from 'src/app/services/perfil.service';
import { OverlayService } from '../services/overlay.service';

@Injectable({
  providedIn: 'root'
})
export class PermissaoAcessoGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPermissaoAcesso(route);
  }

  constructor(
    private perfilService: PerfilService,
    private loginService: LoginService,
    private router: Router,
    private overlayService: OverlayService
  ) {

  }

  private async checkPermissaoAcesso(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      
      this.loginService.getLogin().then(
        (login: Login) => {
          if (login != null) {
            
            this.perfilService.getPerfilAcesso(login.conta.perfil_id).then(
              (perfilAcesso: PerfilAcesso[]) => {
                let acesso: PerfilAcesso | undefined; // Permite undefined
                let rota: string = ""; // Inicializa rota com uma string vazia
  
                // Verifique se route.parent não é null antes de acessar
                if (route.parent && route.parent.routeConfig) {
                  rota = route.parent.routeConfig.path || ""; // Se o path for undefined, atribui uma string vazia
                } else if (route.routeConfig) {
                  rota = route.routeConfig.path || ""; // Fallback para um path vazio se necessário
                }
  
                // Verifica se encontrou um PerfilAcesso com a rota desejada
                acesso = perfilAcesso.find(
                  (el: PerfilAcesso) => el.path === route.routeConfig?.path
                );
  
                if (acesso && !acesso.possui_acesso) { // Verifica se "acesso" não é undefined                  
                  this.router.navigateByUrl('/home', { replaceUrl: true });
                  this.overlayService.toast({
                    message: "Você não possui permissão para acessar essa tela.",
                    color: 'danger'
                  });
                  resolve(false);
                } else {
                  resolve(true);
                }
              }
            );
          } else {
            resolve(true);
          }
        }
      );
    });
  }

}
