import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StatusBar } from '@capacitor/status-bar';
import { MenuComponent } from './core/componente/menu/menu.component';
import { IonicStorageModule } from '@ionic/storage-angular';  // Importando o módulo de Storage

import { IonicSelectableComponent } from 'ionic-selectable';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { Storage } from '@ionic/storage-angular';  // Importando Storage
import { registerLocaleData } from '@angular/common';
import ptPT from '@angular/common/locales/pt-PT'; 
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
registerLocaleData(ptPT);
@NgModule({
  declarations: [AppComponent, MenuComponent],
  imports: [
    HttpClientModule,
    IonicSelectableComponent,
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    IonicStorageModule.forRoot({
      name: '__protix_app',
      driverOrder: ['indexeddb', 'localstorage', 'sqlite', 'websql']
    }),
    NgxMaskDirective, 
    NgxMaskPipe,
    NgxDatatableModule
  ],
  providers: [    
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    provideNgxMask(),
    Storage  // Aqui é onde você fornece o Storage corretamente
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]  // Importante se estiver usando Web Components
})
export class AppModule {}
